:root {
  /* --dedFamGreen: rgb(255, 242, 0); */
  --dedFamGreen: rgb(78 255 14);
  --dedFamBlack: #1b1b1d;
  --dedFamPurple: #bd42ff;
  --dedFamBeige: #f6f1e2;
  --borderRadius: 200px;
  --overlay: rgb(27, 27, 29, .5);
  --navHeight: 8rem;
  --sidescrollerHeight: 8rem;
  --borderWidth: 4px;
  --border: var(--borderWidth) solid var(--dedFamBlack);
}

@font-face {
  font-family: "headlines";
  src: local("headlines"), url(../assets/fonts/Dedfam-Headlines.otf) format("opentype");
}

@font-face {
  font-family: "text";
  src: local("text"), url(../assets/fonts/Dedfam-Text.otf) format("opentype");
}

/* Media queries */
@custom-media --tabletUp (min-width: 768px);
@custom-media --desktopUp (min-width: 1025px);
@custom-media --widescreenUp (min-width: 1325px);

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "headlines";
  color: var(--dedFamBlack);
  font-weight: 300;
}

p,
span,
a {
  color: var(--dedFamBlack);
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.header {
  font-size: 64px;
  margin-bottom: 3rem;
  padding-left: 7rem;
}

.noPaddingHeader {
  padding-left: 10rem;
}

.white {
  color: white;
}

.intro {
  border-top: var(--border);
  min-height: 700px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  margin-bottom: 3rem;
}

.introBlurb {
  z-index: 2;
  font-family: "text";
  font-size: 64px;
  text-align: center;
  width: 70%;
  margin: 4rem 0 5rem;
}

.portraitContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 96vw;
  max-width: 1400px;
}

.app {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
}

.nav {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100vw;
  height: var(--navHeight);
  z-index: 3;
  white-space: nowrap;
}

.links {
  font-family: "headlines";
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5vw;
}

.nav a {
  position: relative;
  padding: 0 16px;
  color: var(--dedFamBlack);
  text-transform: uppercase;
  font-size: 60px;
  font-family: "headlines";
}

.nav>div {
  width: 100%;
}

.marq {
  min-height: 90px;
}

.marqLarge {
  min-height: 216px;
}

.linkHover::after {
  content: "";
  background-color: var(--dedFamBlack);
  font-family: "text";
  display: flex;
  color: var(--dedFamGreen);
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 32px;
  transform: translate(-50%, -50%);
}

.linkHover.soon:hover::after {
  content: "SOON!";
}

.mintGlyph {
  position: relative;
  padding: 0 16px;
}

.mintGlyph img {
  width: 4.25rem;
}

.mintGlyph h3 {
  font-size: 60px;
}

.section {
  width: calc(100vw - 3rem);
  border-radius: var(--borderRadius);
  overflow: hidden;
}

.splashContainer {
  width: 100vw;
  height: 100vh;
  min-height: 725px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}

.splashHorizontal {
  position: relative;
  height: calc(100% - var(--sidescrollerHeight));
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.splash {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.fixedMintButton {
  position: fixed;
  bottom: 10rem;
  right: 9rem;
  transition: all .3s;
  z-index: 8;
  transform: translateY(0);
}

.fixedMintButton.hidden {
  transform: translateY(20rem);
}

.cover {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-bottom: var(--border);
  background-image: url("../assets/backgrounds/Hottub.png");
  background-color: var(--dedFamBlack);
}

.emblemContainer {
  cursor: pointer;
  position: relative;
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 3;
}

.skullCircle {
  height: 80%;
  border-radius: 50%;
  overflow: hidden;
  border: 14px solid var(--dedFamBlack);
  position: absolute;
  z-index: 2;
  margin-bottom: 2rem;
}

.skull {
  z-index: 3;
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin: 3rem 0 0 1rem;
  height: 100%;
}

.dedfamLogo {
  position: absolute;
  bottom: -1rem;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 5;
  width: 60%;
  max-width: 800px;
}

.sidescroller {
  width: 100vw;
  height: var(--sidescrollerHeight);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "text" !important;
  overflow: hidden;
  z-index: 1;
}

.auctionAlt {
  margin-left: 3rem;
}

.sidescroller p {
  white-space: nowrap;
  font-size: 54px;
}

.scrollContainer {
  margin-right: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scrollText {
  color: var(--dedFamBlack);
  font-family: "headlines";
  text-transform: uppercase;
}

.marginLeft {
  margin-left: 1rem;
}

.altText {
  color: var(--dedFamGreen);
  font-family: "text";
  margin-left: 1rem;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.sectionSpacing {
  padding: 5rem 3rem 6rem 3rem;
  text-align: left;
}

.sectionSpacingNoWidth {
  margin: 4rem 0;
  padding: 5rem 0 3rem 0;
  text-align: justify;
}

.nft {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  overflow: hidden;
}

.horizontal {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.splashScrollContainer {
  position: absolute;
  top: 60%;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: fit-content;
  overflow: hidden;
}

.splashScrollContainer h3 {
  font-size: 180px;
  color: var(--dedFamBlack);
  margin-right: 5rem;
}

.splashScrollWorldContainer {
  position: absolute;
  top: calc(60% + 106px);
  transform: translate(0, -50%);
}

.splashScrollWorldContainer p {
  font-size: 75px;
  margin-right: 5rem;
  color: var(--dedFamGreen);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.communityContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: fit-content;
  overflow: hidden;
}

.communityContainer h3 {
  font-size: 180px;
  color: var(--dedFamBlack);
  margin-right: 5rem;
}

.mintContainer {
  padding: 2rem 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mintInfo {
  font-family: "text";
  width: 25%;
  text-align: center;
  transform: rotate(350deg);
  font-size: 45px;
  color: var(--dedFamBlack);
}

.mintButtonContainer {
  width: 258px;
  height: 82px;
  border: var(--border);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.mintButtonContainer:hover {
  background-color: var(--dedFamGreen);
}

.explore {
  cursor: grab;
  padding: 3rem 0;
  overflow: scroll;
  display: flex;
}

.explore>div {
  margin: 0 1rem;
}

.explore>div:first-child {
  margin-left: 0;
}

.explore>div:last-child {
  margin-right: 0;
}

.team {
  background-color: var(--dedFamPurple);
}

.teammatesContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.teammates {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: calc(100% + 3rem);
  max-width: 1400px;
  margin-left: -1.5rem;
}

.footer {
  background-color: var(--dedFamBlack);
  width: 100vw;
  height: 15rem;
  padding: 0 3vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
}

.footerLeft {
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.footerLeft p {
  font-family: "text";
  color: white;
  font-size: 16px;
  margin-top: -.8rem;
}

.footerLeft img {
  width: 240px;
}

@media (max-width:768px) {
  .skullCircle {
    width: 94vw;
    height: 94vw;
  }

  .skull {
    width: 140%;
    height: unset;
  }

  .splashHorizontal {
    overflow: hidden;
  }

  .dedfamLogo {
    width: 100%;
    margin-top: -9rem;
  }

  .mintContainer {
    flex-direction: column;
  }

  .mintInfo {
    width: 80%;
  }

  .mintInfo:first-child {
    margin-bottom: 3rem;
  }

  .mintInfo:last-child {
    margin-top: 3rem;
  }

  .introBlurb {
    font-size: 50px;
    width: 80vw;
  }

  .portraitContainer {
    width: 100vw;
    overflow-x: scroll;
    padding: 0 calc((100vw - 320px) / 2);
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .portraitContainer::-webkit-scrollbar {
    display: none;
  }

  .portraitCard {
    margin-right: 1rem;
  }

  .portraitCard:last-child {
    margin-right: 0;
  }

  .fixedMintButton {
    bottom: 3rem;
    left: 50%;
    transform: translate(-50%, 0);
    width: fit-content;
  }

  .fixedMintButton.hidden {
    transform: translate(-50%, 20rem);
  }

  .footer {
    height: 20rem;
    padding-bottom: 10rem;
  }

  .traitTitle {
    padding-bottom: 1rem;
  }

  .traitTitle h3 {
    margin-left: 0;
    margin-right: 0;
  }

  .traitTitle ul {
    padding: 0 .5rem;
  }

  .traitTitle li {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .traitTitle p {
    font-size: 16px;
    margin: 0;
    width: 80%;
  }

  .sectionSpacingNoWidth {
    margin: 0;
  }

  .header {
    font-size: 44px;
    width: 100%;
    text-align: center;
    padding-left: 0;
  }

  .noPaddingHeader {
    padding-left: 0;
  }

  .explore {
    padding: 1rem calc((100% - 320px) / 2);
  }

  .teammates {
    margin-left: 0;
    overflow-x: scroll;
    width: 100vw;
    padding: 0 calc((100% - 320px) / 2);
    -ms-overflow-style: none;
    scrollbar-width: none;
    justify-content: unset;
  }

  .teammates::-webkit-scrollbar {
    display: none;
  }

  .team {
    padding-left: 0;
    padding-right: 0;
  }
}